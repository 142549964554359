import React from 'react'
import PropTypes from 'prop-types'

const Offerings = ({ gridItems }) => (
  <div className='columns is-multiline'>
    {gridItems.map(item => (
      <div key={item.image} className='column is-6' style={{ borderRadius: '5px' }}>
        <section className='section'>
          <div className='offering-img'>
            <img alt={item.title} src={item.image} style={{ maxHeight: '200px' }} />
          </div>
          {item.link ? (
            <h4 className='has-text-centered'><a href={item.link}>{item.title}</a></h4>
          ) : (
            <h4 className='has-text-centered'>{item.title}</h4>
          )}
          <p>{item.text}</p>
        </section>
      </div>
    ))}
  </div>
)

Offerings.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      text: PropTypes.string,
      title: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
}

export default Offerings
